// Header.jsx
import React from 'react';
import '../styles/main.css';


const Header = () => {
    return (
        <header className="header">
            <div className="header-content">
                <h1 className="logo">ASMRi</h1>
            </div>
        </header>
    );
  };


export default Header;