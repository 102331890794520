import React, { useEffect, useRef, useState } from 'react';
import PlayButton from './PlayButton';
import SoundSelector from './SoundSelector';
import SoundParameters from './SoundParameters';
import RefreshChapterButton from './RefreshChapterButton';
import Timeline from './Timeline';
import Timer from './Timer';
import Knob from './Knob'
import SparkleCanvas from './SparkleCanvas';
import SparkleCanvas2 from './SparkleCanvas2';
import SparkleCanvas3 from './SparkleCanvas3';
import styled from 'styled-components';
import '../styles/main.css';



const KnobContainer = styled.div`
  width: 850px;
  height: 600px;
  margin: 0px auto;
  margin-top: 3.5em;
`;

//**** Radio container 
const Radio = styled.div `
  background-color: none;
  display: flex;  
  border: 4px solid black;
  border-radius: 20px;
  padding: 0px;
  width: 790px;
  height: 400px;
  margin: 0px auto;
  margin-top: -2em;
  overflow: hidden;
`;

const Outside = styled.div `
  bottom: 4em;
  left: 46.1em;
  transform: translate(0, 100%); 
  transition: all 300ms;
  position: relative;
`;

//*** Left Panel
const LeftPanel = styled.div`
  display: flex;  
  justify-content: center;
  align-items: center; 
  padding: 0px;
  gap: 0;
  position: relative;
  height: 100%;
  overflow: hidden;
`;

const AlignedSparkleCanvas = styled.div`
flex: 1;  
display: flex; 
  margin: 0;
  padding: 0;
  gap: 0; 
`;

const AlignedSeamLine = styled.div`
  flex: 1;

`;

//*** Right Panel
const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5em;    
  margin-right: 1em;
  width: 85%;
`;

//** Sub Right Panel (top)
const SubRightPanelTop = styled.div`
  display: flex; 
  width: 100%;
  height: 70%;
`;

const AlignedSoundParameters = styled.div`
  flex: 2;
  position: relative;
  margin-top: 0.75em;
`;

const AlignedSoundSelector = styled.div`
  flex: 3;
  position: relative;
  margin-top: 2.5em;
  margin-left: 3.5em;
  width: 100%
`;

//** Sub Right Panel (middle)
const SubRightPanelMiddle = styled.div`
  flex: 1
  display: flex;
  width: 100%;
  height: 10%;
  align-items: center;
  justify-content center;
  margin-bottom: 0.5em;
`;

//** Sub Right Panel (bottom)
const SubRightPanelBottom = styled.div`
  flex: 1;
  display: flex;  
  width: 100%;
  align-items: center;
  justify-content center;
  margin-bottom: 1.5em;
`;

const AlignedPlayButton = styled.div`
  display: flex;
  flex-direction: row; 
  margin-left: 0.75em;

`;

const AlignedTimeline = styled.div`
  flex: 5;
  align-items: center;
  justify-content center;
`;

const AlignedTimer = styled.div`
  flex: 1;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  margin-right: 2em;
`;

// Functional component to render the ASMR player
const AsmrPlayer = ({ onStart, onStop, playing, currentParameters }) => {
  const audioRef = useRef(null);  // Create a reference for the audio element
  const [soundParams, setSoundParams] = useState({
    volume: 3,
    speed: 3,
    variation: 3,
    length: 3,
  });  // State to manage sound parameters
  const [totalTime, setTotalTime] = useState(0);  // State to manage the total time of all chapters
  const [duration, setDuration] = useState(0);
  const [hasChapters, setHasChapters] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentChapterUrl, setCurrentChapterUrl] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [soundType, setSoundType] = useState('');

  const handleSoundTypesChange = (selectedSoundTypes) => {
    // If multiple sound types can be selected, join them into a string
    const soundType = selectedSoundTypes.join(', ');

    // Update the soundType state
    setSoundType(soundType);
};

  // Handler to start and stop the audio playback
  const handlePlay = () => {
    if (playing) {
      onStop();
    } else {
      onStart();
    }
  };

  // useEffect to handle playing and pausing the audio based on the 'playing' prop
  useEffect(() => {
    if (playing && audioRef.current) {
      audioRef.current.play();
    } else if (audioRef.current) {
      audioRef.current.pause();
    }
  }, [playing]);

// useEffect to update the audio source when the current chapter URL changes
  useEffect(() => {
    let interval = null; // Initialize interval variable
    if (playing) {
      // If audio is playing, set up an interval to update the current time every second
      interval = setInterval(() => {
        if (audioRef.current) {
          setCurrentTime(audioRef.current.currentTime); // Update the current time state with the audio's current time
        }
      }, 1000); // Update every second
    } else if (!playing && currentTime !== 0) {
      // If audio is paused and current time is not zero, clear the interval
      clearInterval(interval);
    }
    return () => clearInterval(interval); // Clean up the interval when component unmounts or when dependencies change
  }, [playing, currentTime]);

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration); // Set the duration state based on the audio's duration
    }
  };


  // Handler to change sound parameters
  const handleSoundControlChange = (parameter, value) => {
    setSoundParams((prev) => ({
      ...prev,
      [parameter]: value,
    }));
  };

  // useEffect to update the slider background color based on the value
  useEffect(() => {
    document.querySelectorAll('.slider').forEach((slider) => {
        const value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
        slider.style.background = `linear-gradient(to right, #000 ${value}%, #ddd ${value}%)`;
    });
  }, [soundParams]);  


  // Handler to add new chapters
  const handleAddChapter = async () => {
    try {
      const response = await fetch('http://localhost:3001/api/asmrChapter/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...soundParams,
          type: soundType,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to add chapter');
      }
  
      const newChapters = await response.json();
      setChapters([newChapters]); // Set the new chapter directly
      setCurrentChapterUrl(newChapters[0].url);
      setHasChapters(true); // Update the hasChapters state
      setTotalTime(parseInt(soundParams.length, 10)); // Set the total time to the length of the new chapter
      console.log('Added new chapters:', newChapters);
    } catch (error) {
      console.error('Error adding chapters:', error);
    } 
  };

  // Handler to update the hasChapters state
  const handleChapterChange = (chapterExists) => {
    setHasChapters(chapterExists);
  };

  const handleRemoveChapter = () => {
    setChapters([]);
    handleChapterChange(false);
  };

  const handleMouseOver = (chapter) => {
    setCurrentDescription(chapter.description);
  };


<p>{currentDescription}</p>

  return (
    <div className="asmr-player">
      <KnobContainer>
        <Outside>
          <Knob />
        </Outside>
        <Radio>
          <LeftPanel>
            <AlignedSparkleCanvas>
              <SparkleCanvas isPlaying={playing} />
              <SparkleCanvas2 isPlaying={playing} />
              <SparkleCanvas3 isPlaying={playing} />
            </AlignedSparkleCanvas>
            <AlignedSeamLine>
              <div className="seam-line"></div>
            </AlignedSeamLine>
          </LeftPanel>
          <RightPanel>
            <SubRightPanelTop>
                <AlignedSoundParameters>
                  <SoundParameters soundParams={soundParams} handleSoundControlChange={handleSoundControlChange} />
                </AlignedSoundParameters> 
                <AlignedSoundSelector> 
                    <SoundSelector handleSoundTypesChange={handleSoundTypesChange} />
                </AlignedSoundSelector>
            </SubRightPanelTop>
            <SubRightPanelMiddle>
              <RefreshChapterButton playing={playing} handleRemoveChapter={handleRemoveChapter} />
            </SubRightPanelMiddle>  
            <SubRightPanelBottom>
              <AlignedPlayButton> 
                <PlayButton handlePlay={handlePlay} playing={playing} onStart={onStart} handleAddChapter={handleAddChapter} hasChapters={hasChapters} setHasChapters={setHasChapters} />
              </AlignedPlayButton>
              <AlignedTimeline>
                <Timeline currentTime={currentTime} totalTime={totalTime} duration={duration} handleMouseOver={handleMouseOver} chapters={chapters} />
              </AlignedTimeline>
              <AlignedTimer> 
                <Timer totalTime={totalTime} />
              </AlignedTimer>
            </SubRightPanelBottom>
          </RightPanel>
        </Radio>
      </KnobContainer>
      <audio ref={audioRef} src={currentChapterUrl} onLoadedMetadata={handleLoadedMetadata} onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)} />
    </div>
  );
};

export default AsmrPlayer;