import React from 'react';

const RefreshChapterButton = ({ playing, handleRemoveChapter }) => {
  return (
    <button id="refresh-button" onClick={handleRemoveChapter} disabled={!playing}>
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.76 41.38" width="25px" height="25px">
        <defs>
            <style>{`
            .cls-1 {
                fill: #ba0000;
                stroke-width: 0px;
            }`}
            </style>
        </defs>
        <g id="Layer_3" data-name="Layer 3">
            <g>
            <path class="cls-1" d="M20.03,39.26l-3.74-3.77c2.54-.24,4.98-1.12,7.12-2.62,3.26-2.28,5.43-5.69,6.12-9.6.99-5.62-1.37-11.39-6.02-14.71l-1.74,2.44c3.71,2.65,5.6,7.26,4.8,11.74-.55,3.13-2.29,5.85-4.89,7.67-1.7,1.19-3.64,1.89-5.65,2.08l4.02-3.99-2.12-2.13-7.52,7.47,7.47,7.52,2.13-2.12Z"/>
            <path class="cls-1" d="M3.18,18.62c.95-5.36,5.36-9.23,10.53-9.75l-4.01,3.99,2.12,2.13,7.52-7.47L11.85,0l-2.13,2.12,3.75,3.77C6.98,6.51,1.41,11.37.22,18.1c-.99,5.62,1.37,11.39,6.02,14.71l1.74-2.44c-3.71-2.65-5.6-7.26-4.8-11.74Z"/>
            </g>
        </g>
        </svg>
    </button>
  );
};

export default RefreshChapterButton;