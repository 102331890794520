import React from 'react';

const PlayButton = ({ handleAddChapter, handlePlay, playing, onStop, hasChapters, setHasChapters }) => {

  const handlePlayClick = async () => {
    if (!hasChapters) {
      await handleAddChapter();
      setHasChapters(true);
    }
    handlePlay();
  };

  return (
    <div>
      {!playing && (
        <button id="play-button" onClick={handlePlayClick} className="button-style">
          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.14 94.14" width="50px" height="50px">
            <defs>
              <style> {`
                .cls-play1 {
                  fill: #1c5200;
                }

                .cls-play1, .cls-play2 {
                  stroke-width: 0px;
                }

                .cls-play2 {
                  fill: #2f8700;
                }
                `}
              </style>
            </defs>
            <g id="blinky_button" data-name="blinky button">
              <circle class="cls-play2" cx="47.07" cy="47.07" r="47.07"/>
              <path class="cls-play1" d="M66.1,44.44l-26.27-15.17c-2.02-1.17-4.55.29-4.55,2.62v30.34c0,2.33,2.53,3.79,4.55,2.62l26.27-15.17c2.02-1.17,2.02-4.08,0-5.25Z"/>
            </g>
          </svg>
        </button>
      )}
      {playing && (
        <div className="stop-pause">
         <button id="stop-button" onClick={onStop} className="button-style">
         <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.34 72.34"  width="50px" height="50px">
            <defs>
              <style> {`
                .cls-1-pause {
                  fill: #016;
                }

                .cls-1-pause, .cls-2-pause {
                  opacity: .83;
                  stroke-width: 0px;
                }

                .cls-2-pause {
                  fill: #0069e8;
                }
                `}
              </style>
            </defs>
            <g id="Layer_2-2" data-name="Layer 2">
              <circle class="cls-2-pause" cx="36.17" cy="36.17" r="36.17"/>
              <g>
                <rect class="cls-1-pause" x="21.88" y="17.48" width="11.03" height="37.37" rx="4.66" ry="4.66"/>
                <rect class="cls-1-pause" x="39.42" y="17.48" width="11.03" height="37.37" rx="4.66" ry="4.66"/>
              </g>
            </g>
          </svg>
         </button>
        </div>
      )}
    </div>
  );
};

export default PlayButton;
