import React, { useEffect, useRef } from 'react';

const SparkleCanvas = ( {isPlaying} ) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Define the colors
    const colors = ['#111111', '#141414', '#171717', '#1A1A1A', '#1D1D1D', '#202020', '#232323', '#262626', '#292929', '#2C2C2C', '#2F2F2F', '#323232', '#353535', '#383838', '#3B3B3B', '#3E3E3E'];
    const sparkleArea = {
      x: 0,
      y: 0,
      width: 80,
      height: 400,
    };

    let lastUpdateTime = 0;
    const sparkleDelay = 325;
    let offset = 10;
    const step = 15;

    function drawSparkle(timestamp) {
      if (!isPlaying) {
        context.fillStyle = '#2C2C2C';
        context.fillRect(0, 0, canvas.width, canvas.height);
        return;
      }

      if(timestamp - lastUpdateTime > sparkleDelay ) {
        context.clearRect(sparkleArea.x, sparkleArea.y, sparkleArea.width, sparkleArea.height);

        // Draw the background rectangle
        context.fillStyle = '#FFF1E6';
        context.fillRect(sparkleArea.x, sparkleArea.y, sparkleArea.width, sparkleArea.height);
  
        for (let i = sparkleArea.x; i < sparkleArea.x + sparkleArea.width; i += 5) {
          for (let j = sparkleArea.y; j < sparkleArea.y + sparkleArea.height; j += 5) {
            const color = colors[Math.floor(Math.random() * colors.length)];
            context.fillStyle = color;
            context.fillRect(i, j, 5, 5);
         }
        }

        // Update the offset to create the gradation effect
        offset += step;
        if (offset > sparkleArea.width) {
          offset = 0; // Reset to start the gradation effect again
        }
        

        lastUpdateTime = timestamp;
      }

      // Schedule the next frame
      requestAnimationFrame(drawSparkle);
    }

    // Start the animation
    requestAnimationFrame(drawSparkle);

  }, [isPlaying]);

  return <canvas ref={canvasRef} width="80" height="400" style={{ border: 'none' }}></canvas>;
};

export default SparkleCanvas;
