import React, { useState } from 'react';
import MessageModal from './MessageModal';
import FAQModal from './FAQModal';

const Footer = () => {
    const [isMessageModalOpen, setMessageModalOpen] = useState(false);
    const [isFAQModalOpen, setFAQModalOpen] = useState(false);

    const openMessageModal = () => setMessageModalOpen(true);
    const closeMessageModal = () => setMessageModalOpen(false);

    const openFAQModal = () => setFAQModalOpen(true);
    const closeFAQModal = () => setFAQModalOpen(false);

    return (
        <footer className="footer">
            <button id="message-button" onClick={openMessageModal} aria-label="Send Message">
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.36 34.06">
                    <defs>
                        <style> {`
                        .cls-1 {
                            fill: #000;
                            stroke-width: 0px;
                        `}
                        </style>
                    </defs>
                    <g id="Layer_3" data-name="Layer 3">
                        <g>
                        <path class="cls-1" d="M30.08,34.06H8.28c-4.56,0-8.28-3.71-8.28-8.28V8.28C0,3.71,3.71,0,8.28,0h21.81c4.56,0,8.28,3.71,8.28,8.28v17.5c0,4.56-3.71,8.28-8.28,8.28ZM8.28,4.47c-2.1,0-3.81,1.71-3.81,3.81v17.5c0,2.1,1.71,3.81,3.81,3.81h21.81c2.1,0,3.81-1.71,3.81-3.81V8.28c0-2.1-1.71-3.81-3.81-3.81H8.28Z"/>
                        <path class="cls-1" d="M19.18,18.6c-1.2,0-2.39-.29-3.51-.86L1.21,10.26l2.05-3.97,14.46,7.47c.94.48,1.97.48,2.91,0l14.46-7.47,2.05,3.97-14.46,7.47c-1.11.58-2.31.86-3.51.86Z"/>
                        </g>
                    </g>
                </svg>
            </button>
            <button id="faq-button" onClick={openFAQModal} aria-label="FAQ">
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.67 67.67">
                <defs>
                    <style> {`
                    .cls-1 {
                        fill: #000;
                        stroke-width: 0px;
                        `}
                    </style>
                </defs>
                <g id="Layer_3" data-name="Layer 3">
                    <path class="cls-1" d="M36.07,44.71h-4.47v-8.76h2.24c5.32,0,9.65-4.33,9.65-9.65s-4.33-9.65-9.65-9.65-9.65,4.33-9.65,9.65h-4.47c0-7.79,6.34-14.12,14.12-14.12s14.12,6.34,14.12,14.12c0,7.03-5.16,12.87-11.89,13.95v4.46Z"/>
                    <circle class="cls-1" cx="33.84" cy="52.01" r="2.95"/>
                    <path class="cls-1" d="M33.84,67.67C15.18,67.67,0,52.49,0,33.84S15.18,0,33.84,0s33.84,15.18,33.84,33.84-15.18,33.84-33.84,33.84ZM33.84,4.47c-16.19,0-29.36,13.17-29.36,29.36s13.17,29.36,29.36,29.36,29.36-13.17,29.36-29.36S50.03,4.47,33.84,4.47Z"/>
                </g>
            </svg>
            </button>
            <MessageModal isOpen={isMessageModalOpen} onClose={closeMessageModal} />
            <FAQModal isOpen={isFAQModalOpen} onClose={closeFAQModal} />
        </footer>
    );
};

export default Footer;