import React, { useEffect, useState } from 'react';

const SoundParameters = ({ soundParams, handleSoundControlChange }) => {
  const [params, setParams] = useState({
    volume: 3,
    speed: 3,
    variation: 3,
    length: 3,
  });

  useEffect(() => {
    setParams(soundParams); // Ensure props are setting initial state
  }, [soundParams]);

  const handleChange = (parameter, value) => {
    handleSoundControlChange(parameter, value);
    setParams((prev) => ({ ...prev, [parameter]: value }));
  };

  return (
    <div className="asmr-parameter-controls">
      {['volume', 'speed', 'variation', 'length'].map((param) => (
        <div className="parameter-controls" key={param}>
          <label htmlFor={param}>{param.toLowerCase()}</label>
          <input
            type="range"
            id={param}
            className="slider"
            min="1"
            max="5"
            value={params[param]}
            onChange={(e) => handleChange(param, e.target.value)}
          />
        </div>
      ))}
    </div>
  );
};

export default SoundParameters;
