// FAQModal.jsx
import React from 'react';
import '../styles/main.css'
import howModal from '../assets/howModal.svg'
import pierce from '../assets/pierce.png'
import elif from '../assets/elif.png'

const FAQModal =({ isOpen, onClose, section }) => {
    if (!isOpen) return null;

    const getContent = () => {
        switch (section) {
            case 'what':
                return (
                    <div>
                        <h2>what is it?</h2>
                        <p>ASMRi uses customizable ASMR to enhance your mood and sleep. we recorded thousands of different ASMR sounds, then organized them into a database. when you select your presets (e.g., sleep) or craft your own experiences, ASMRi automatically organizes hundreds of various sounds into "chapters" for you. each chapter is unique, which means each ASMR experience is unique, every time!</p>
                    </div>
                );
            case 'how':
                return (
                    <div>
                        <h2>how does it work?</h2>
                    <img src={howModal} alt="How does it work" style={{ width: '500px', height: '100px'}} />
                    </div>
                );
            case 'who':
                return (
                    <div>
                        <h2>Who made it</h2>
                        <div className="creators">
                            <img src={pierce} alt="Pierce" style={{ width: '50px', height: '50px'}} />
                            <img src={elif} alt="Elif" style={{ width: '50px', height: '50px'}} />
                        </div>
                        <p>makers:pierce erez-henderson and elif erez-henderson. we're designer and ASMR enthusiasts. drop us a note: pierce@email.com </p>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                {getContent()}
            </div>
        </div>
    );
};

export default FAQModal;