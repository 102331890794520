import React, { useState } from 'react';
import './styles/main.css';
import AsmrPlayer from './components/AsmrPlayer';
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  const [playing, setPlaying] = useState(false);
  const [currentParameters, setCurrentParameters] = useState(null);

  const handleStart = () => {
    setPlaying(true);
  };

  const handleStop = () => {
    setPlaying(false);
  };

  const handlePause = () => {
    setPlaying(false);
  }

  
  return (
    <div className="App">
      <Header />
      <AsmrPlayer 
        onStart={handleStart}
        onStop={handleStop}
        onPause={handlePause}
        playing={playing}
        currentParameters={currentParameters}
      />
      <Footer />
    </div>
  );
};

export default App;
