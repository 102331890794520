import React from 'react';

const Timer = ({ totalTime }) => {
    const minutes = Math.floor(totalTime / 60);
    const seconds = totalTime % 60;
    return (
        <div className="timer">
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </div>
    );
};

export default Timer;