import React, { useState } from 'react';
import '../styles/main.css'

const soundOptions = [
    'drip', 'tap', 'scratch', 'squish', 'blow', 'crack', 'cut', 'brush', 'cluster', 'snap', 'tinker', 'click'
];

const SoundSelector = ({ handleSoundTypesChange }) => {
    const [selectedSounds, setSelectedSounds] = useState([]);

    const handleSoundChange = (event) => {
        const sound = event.target.value;
        setSelectedSounds(prevSounds => {
            if (prevSounds.includes(sound)) {
                // If the sound is already selected, remove it from the selection
                return prevSounds.filter(s => s !== sound);
            } else {
                // If the sound is not selected, add it to the selection
                return [...prevSounds, sound];
            }
        });
    };
    
    return (
        <div className="sound-selector-container">
        {soundOptions.map((sound, index) => (
            <div key={index} className={`sound-option ${index % 4 === 0 ? 'new-row' : ''}`}>
                <input 
                    type="radio" 
                    id={sound} 
                    name={sound} // each radio button has a unique name
                    value={sound} 
                    checked={selectedSounds.includes(sound)} 
                    onClick={handleSoundChange} 
                />
                <label htmlFor={sound}>{sound}</label>
            </div>
        ))}
    </div>
    );
};

export default SoundSelector;