import React, { useState } from 'react';

const Knob = () => {
  const [isPulsing, setIsPulsing] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const handleClick = () => {
    setIsPulsing(!isPulsing);
  };

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  return (
    <div 
      className={`knob ${isPulsing ? 'pulse' : ''} ${isPressed ? 'pressed' : ''}`} 
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // Ensure the knob returns to normal size when the mouse leaves the area
    >
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.69 32.31"
        width="1.5em"
        height="2.25em"
      >
        <defs>
          <style>
            {`
            .cls-knob1 {
              fill: none;
              stroke: #000;
              stroke-width: 4.5px;
            }
            .pressed {
              transition: transform 0.2s;
              transform: scaleY(1.2); // Stretch the knob vertically
            }
            `}
          </style>
          <linearGradient id="gradient" gradientTransform="rotate(90)">
            {/* ... */}
          </linearGradient>
        </defs>
        <g id="blinky_button" data-name="blinky button">
          <path
            className={`cls-knob1 ${isPulsing ? 'pulse' : ''}`}
            d="M2.5,32.31V13.34C2.5,7.36,7.36,2.5,13.34,2.5h0c5.99,0,10.84,4.86,10.84,10.84v18.97"
            fill={isPulsing ? 'url(#gradient)' : 'none'} // Apply gradient to path
          />
        </g>
      </svg>
    </div>
  );
};

export default Knob;