import React, { useState } from "react";
import axios from 'axios';
import '../styles/main.css'

const MessageModal =({ isOpen, onClose }) => {
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/message', { name, message });
            console.log('Message sent:', response.data);
            // Clear the form
            setName('');
            setMessage('');
            onClose();
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    if (!isOpen) {
        return null;
    }
    return (
        <div className="modal">
            <div className="modal-content">
                <button className="close" onClick={onClose}>
                    &times;
                </button>
                <form onSubmit={handleSubmit}>
                    <label>
                        name (not required)
                        <input 
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </label>
                    <label>
                        message
                        <textarea  
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                    </label>
                    <button type="submit">Send</button>
                </form>
            </div>
        </div>
    );
};

export default MessageModal