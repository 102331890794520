import React from 'react';

const Timeline = ({ chapters, handleMouseOver, currentTime, totalTime }) => {
    const progress = (currentTime / totalTime) * 100;
    
    console.log('Rendering Timeline', chapters);
    return (
        <div className="timeline">
            {chapters.map((chapter, index) => (
                <button key={index} onMouseOver={() => handleMouseOver(chapter)}>
                Chapter {index + 1}
                </button>
            ))}
               <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
    );
};

export default Timeline;


<div className="chapter-box" style={{ backgroundColor: '#000' }}></div>
